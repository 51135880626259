import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS, NetworkActionAuth, NetworkActionAuthTypes } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { Input, Select } from "Components/Global/Common";
import { updateSelectedActionMethod, updateSelectedActionURI, updateSelectedActionAuthType,
	updateSelectedActionAuthName, updateSelectedActionAuthUsername, updateSelectedActionAuthPassword,
	updateSelectedActionAuthToken, updateSelectedActionAuthValue} from "Data/Actions/ActionsAsync";
import inputValidation from "Data/Objects/Validation";
import { getSelectedActionMethod, getSelectedActionType, getSelectedActionURI,
	getSelectedActionAuth } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";
import { toggleFeature } from "@connect/Features";


const { Option } = Select;

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		method: getSelectedActionMethod(state),
		uri: getSelectedActionURI(state),
		auth: getSelectedActionAuth(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateURI: (uri: string) => dispatch(updateSelectedActionURI(uri)),
		updateMethod: (method: "get" | "post") => dispatch(updateSelectedActionMethod(method)),
		updateAuthType: (authType: NetworkActionAuthTypes) => dispatch(updateSelectedActionAuthType(authType)),
		updateAuthName: (name: string) => dispatch(updateSelectedActionAuthName(name)),
		updateAuthUsername: (username: string) => dispatch(updateSelectedActionAuthUsername(username)),
		updateAuthPassword: (password: string) => dispatch(updateSelectedActionAuthPassword(password)),
		updateAuthToken: (token: string) => dispatch(updateSelectedActionAuthToken(token)),
		updateAuthValue: (value: string) => dispatch(updateSelectedActionAuthValue(value))
	}
}

interface NetworkActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	method: "get" | "post";
	uri: string;
	auth: NetworkActionAuth;
	updateURI: (uri: string) => void;
	updateMethod: (method: "get" | "post") => void;
	updateAuthType: (authType: NetworkActionAuthTypes) => void;
	updateAuthName: (name: string) => void;
	updateAuthUsername: (username: string) => void;
	updateAuthPassword: (password: string) => void;
	updateAuthToken: (token: string) => void;
	updateAuthValue: (value: string) => void;
}

export class NetworkActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<NetworkActionPropertiesPanelContentProps> {
	constructor(props: NetworkActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.network_request;

		this.icon = icon;
		this.title = title;
		this.styles = {
			...this.styles,
			methodSelect: {
				width: "100%",
				marginBottom: 10
			}
		}
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer(this.renderContent());
	}

	renderContent() {
		const { methodSelect } = this.styles;
		const { method, updateMethod, uri, updateURI } = this.props;

		return (
			<div>
				<div>Method:</div>
				<Select
					key={ method }
					defaultValue={ method }
					onChange={ updateMethod }
					style={ methodSelect }
				>
					<Option value="get">GET</Option>
					<Option value="post">POST</Option>
				</Select>
				<div>URL:</div>
				<Input
					key={ uri }
					id="network-action-uri"
					value={ uri }
					saveCallback={ updateURI }
					validator={ inputValidation.uri }
				/>
				{ this.renderAuthContent() }
			</div>
		);
	}

	renderAuthContent() {
		const { methodSelect } = this.styles;
		const { auth: { type }, updateAuthType } = this.props;

		return toggleFeature(
			"triggered-action-auth",
			(
				<React.Fragment>
					<div>Authentication:</div>
					<Select
						key={ type }
						defaultValue={ type }
						onChange={ updateAuthType }
						style={ methodSelect }
					>
						<Option value={ NetworkActionAuthTypes.NONE }>None</Option>
						<Option value={ NetworkActionAuthTypes.BASIC }>Basic</Option>
						<Option value={ NetworkActionAuthTypes.DIGEST }>Digest</Option>
						<Option value={ NetworkActionAuthTypes.BEARER }>Bearer Token</Option>
						<Option value={ NetworkActionAuthTypes.HEADER }>Custom Header</Option>
					</Select>
					{ this.renderAuthenticationFields() }
				</React.Fragment>
			),
			null
		)
	}

	renderAuthenticationFields() {
		const { auth: { type } } = this.props;
		switch (type) {
			case NetworkActionAuthTypes.BASIC:
				return this.renderBasicAuthFields();
			case NetworkActionAuthTypes.DIGEST:
				return this.renderDigestAuthFields();
			case NetworkActionAuthTypes.BEARER:
				return this.renderBearerTokenFields();
			case NetworkActionAuthTypes.HEADER:
				return this.renderCustomHeaderFields();
			case NetworkActionAuthTypes.NONE:
			default:
				return null;
		}
	}

	renderBasicAuthFields() {
		const { auth: { username, password }, updateAuthUsername, updateAuthPassword } = this.props;
		return (
			<React.Fragment>
				<div>Username:</div>
				<Input
					key={ `basic-username-${ username }` }
					id="network-action-username"
					value={ username ?? "" }
					saveCallback={ updateAuthUsername }
				/>
				<div>Password:</div>
				<Input
					key={ `basic-password-${ password }` }
					id="network-action-password"
					value={ password ?? "" }
					saveCallback={ updateAuthPassword }
					type="password"
					autoComplete="false"
				/>
			</React.Fragment>
		)
	}

	renderDigestAuthFields() {
		const { auth: { username, password }, updateAuthUsername, updateAuthPassword } = this.props;
		return (
			<React.Fragment>
				<div>Username:</div>
				<Input
					key={ `digest-username-${ username }` }
					id="network-action-username"
					value={ username ?? "" }
					saveCallback={ updateAuthUsername }
				/>
				<div>Password:</div>
				<Input
					key={ `digest-password-${ password }` }
					id="network-action-authentication"
					value={ password ?? "" }
					saveCallback={ updateAuthPassword }
					type="password"
					autoComplete="false"
				/>
			</React.Fragment>
		)
	}

	renderBearerTokenFields() {
		const { auth: { token }, updateAuthToken } = this.props;
		return (
			<React.Fragment>
				<div>Bearer Token:</div>
				<Input
					key={ `token-${ token }` }
					id="network-action-token"
					value={ token ?? "" }
					saveCallback={ updateAuthToken }
					type="password"
					autoComplete="false"
				/>
			</React.Fragment>
		)
	}

	renderCustomHeaderFields() {
		const { auth: { name, value }, updateAuthName, updateAuthValue } = this.props;
		return (
			<React.Fragment>
				<div>Header Name:</div>
				<Input
					key={ `header-name-${ name }` }
					id="network-action-name"
					value={ name ?? "" }
					saveCallback={ updateAuthName }
				/>
				<div>Header Value:</div>
				<Input
					key={ `header-value-${ value }` }
					id="network-action-value"
					value={ value ?? "" }
					saveCallback={ updateAuthValue }
				/>
			</React.Fragment>
		)
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkActionPropertiesPanelContent);