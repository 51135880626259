import { DeviceFilterTypes, DeviceLEDSettingName, NameUuid, PlaylistWithoutAds, SortTypes } from "@connect/Interfaces";

export interface DeviceStatus {
	deployment: string;
	ethernetConnected: boolean;
	heartbeat: string;
	ip: string;
	lastDeploymentFailed: boolean;
	playlist: string;
	pusherConnected: boolean;
	uptime: number; // in seconds
	wifiConnected: boolean;
	availableStorage: number;
	availableMemoryUsage: number;
	cameraOn: boolean;
	cpuTemp: number;
	displayOn: boolean;
	heapMemoryUsage: number;
	heapTotalSize: number;
	lastScheduleDownload: string;
	ledStatus: string;
	memoryTotalSize: number;
	timestamp: string;
	totalStorage: number;
	wifiNetworkName: string;

	/* eslint-disable camelcase */
	available_external_storage: number;
	available_internal_storage: number;
	available_memory_usage: number;
	camera_on: boolean;
	cpu_temp: number;
	display_on: boolean;
	ethernet_connected: boolean;
	heap_memory_usage: number;
	last_schedule_download: string;
	led_status: string;
	pusher_connected: boolean;
	wifi_connected: boolean;
	wifi_network_name: string;
	/* eslint-enable camelcase */
}

export interface DeviceLEDSetting {
	enabled: boolean;
	flashing: boolean;
	motion: boolean;
	name: DeviceLEDSettingName;
	preset?: number;
}

export interface IDevice {
	company: string;
	deviceGroup: NameUuid;
	ethernetMac: string;
	group: NameUuid;
	model: string;
	name: string;
	playlist: PlaylistWithoutAds;
	rotateScreen: boolean;
	serial: string;
	sleepModeEnabled: boolean;
	snapshot: string;
	snapshotThumbnail: string;
	status?: DeviceStatus;
	store: string;
	type: string;
	uuid: string;
	wifiMac: string;

	/* eslint-disable camelcase */
	audio_level: number;
	cec_serial: string;
	created_at: string;
	install_date: string;
	led_settings: DeviceLEDSetting[];
	snapshot_timestamp: string
	software_version: string;
	updated_at: string;
	/* eslint-enable camelcase */

	// API V2 compatibility
	activeDeployment: {
		name: string;
		uuid: string;
	} | null;
	audioLevel: number;
	cecSerial: string;
	createdAt: string;
	installDate: string;
	integrator: string;
	ledSettings: DeviceLEDSetting[];
	scheduleId: string;
	snapshotTimestamp: string;
	softwareVersion: string;
	timezone: string;
	updatedAt: string;
	updatedBy: string;
	firmwareVersion: string;
}

export type PartialDevice = Partial<IDevice>;

export interface IDeviceGroup {
	uuid: string;
	name: string;
	children: string[];
	parent: string;
	devicesCount: number;
	devicesTotal: number;

	/* eslint-disable camelcase */
	created_at: string;
	updated_at: string;
	devices_count: number;
	devices_total: number;
	/* eslint-enable camelcase */
}

export interface UIDeviceGroup extends IDeviceGroup {
	isExpanded?: boolean;
	isVisible?: boolean;
	nestingLevel?: number;
	id?: number;
}

export type PartialDeviceGroup = Partial<UIDeviceGroup>;

export interface DeviceError {
	message: string;
	title: string;
	uuid: string;

	/* eslint-disable camelcase */
	created_at: string;
	occurred_at: string;
	updated_at: string;
	/* eslint-enable camelcase */
}

export type DeviceCapability = (
	"alarm_0" |
	"alarm_out" |
	"button_front" |
	"camera" |
	"camera:ip" |
	"camera:ex" |
	"camera:portrait" |
	"camera:cec" |
	"lightbar"
);

export enum DeviceCapabilities {
	ALARM_IN_0 = "alarm_0",
	ALARM_OUT = "alarm_out",
	BUTTON_FRONT = "button_front",
	CAMERA = "camera",
	CAMERA_AXIS = "camera:ip",
	CAMERA_EX = "camera:ex",
	CAMERA_PORTRAIT = "camera:portrait",
	CAMERA_CEC = "camera:cec",
	LIGHTBAR = "lightbar"
}

export interface DeviceModel {
	title: string;
	description: string | null;
	capabilities: string[];
}

export interface DeviceDispatch {
	device: IDevice;
	companyUuid?: string;
}

export interface DevicesDispatch {
	devices: IDevice[];
	reset?: boolean;
	companyUuid?: string;
}

export interface DeviceGroupDispatch {
	deviceGroup: IDeviceGroup;
}

export interface DeviceGroupsDispatch {
	deviceGroups: IDeviceGroup[];
	reset?: boolean;
}

export interface DeviceModelsDispatch {
	deviceModels: DeviceModel[];
}

export interface DeviceCollectionArgs {
	type?: DeviceFilterTypes;
	sort?: SortTypes;
	sizes?: string[];
	status?: string[];
	stores?: string[];
	filter?: string;
	group?: string;
	integrator?: string;
}

export interface DeviceSelectionArgs {
	model?: string;
	filter?: string;
	sort?: SortTypes; // this is used for the selector -- we should not provide this attribute of the object
	store?: string;
}

export interface DeviceGroupChildren {
	devices: string[];
	deviceGroups: string[];
}

export enum LightbarPattern {
	SLOW_RED_STROBE = "Slow Red Strobe",
	SLOW_BLUE_STROBE = "Slow Blue Strobe",
	SLOW_GREEN_STROBE = "Slow Green Strobe",
	FAST_RED_STROBE = "Fast Red Strobe",
	FAST_BLUE_STROBE = "Fast Blue Strobe",
	FAST_GREEN_STROBE = "Fast Green Strobe",
	POLICE_1 = "Police 1",
	POLICE_2 = "Police 2",
	RED_CHASE = "Red Chase",
	WHITE_CHASE = "White Chase",
	SLOW_WHITE_PULSE = "Slow White Pulse",
	GREEN_PULSE = "Green Pulse",
	RAINBOW = "Rainbow"
};

export const LightbarPatterns = [
	LightbarPattern.SLOW_RED_STROBE,
	LightbarPattern.SLOW_BLUE_STROBE,
	LightbarPattern.SLOW_GREEN_STROBE,
	LightbarPattern.FAST_RED_STROBE,
	LightbarPattern.FAST_BLUE_STROBE,
	LightbarPattern.FAST_GREEN_STROBE,
	LightbarPattern.POLICE_1,
	LightbarPattern.POLICE_2,
	LightbarPattern.RED_CHASE,
	LightbarPattern.WHITE_CHASE,
	LightbarPattern.SLOW_WHITE_PULSE,
	LightbarPattern.GREEN_PULSE,
	LightbarPattern.RAINBOW
]